import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.item.valid ? "mdi-checkbox-marked-circle-outline" : "mdi-close-circle-outline"))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.name))]),_c(VListItemSubtitle,[_vm._v("ID: "+_vm._s(_vm.item.id))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.discount))])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(_vm._s(_vm.item.name))]),_c(VSpacer),_c(VBtn,{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',[_vm._v("Form here")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.update}},[_c('span',[_vm._v("Save Changes")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }