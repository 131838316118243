













































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import { required, minLength, minValue } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    name: { required, min: minLength(3) },
    description: { required, min: minLength(5) },
    unitAmount: { required, min: minValue(1) },
    employees: { required, min: minValue(2) },
  },
})
export default class CreateProduct extends Vue {
  @Getter("products/loading") loading!: boolean;
  @Action("products/create") createProduct!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  dialog = false;
  name = "";
  description = "";
  active = false;
  unitAmount = 10;
  employees = 2;

  // Errors
  get nameErrors() {
    const err: string[] = [];
    if (!this.$v.name!.$dirty) return err;
    if (!this.$v.name!.required) err.push("Required field");
    if (!this.$v.name!.min) err.push("Name is too short");
    return err;
  }
  get descErrors() {
    const err: string[] = [];
    if (!this.$v.description!.$dirty) return err;
    if (!this.$v.description!.required) err.push("Required field");
    if (!this.$v.description!.min) err.push("Description is too short");
    return err;
  }
  get priceErrors() {
    const err: string[] = [];
    if (!this.$v.unitAmount!.$dirty) return err;
    if (!this.$v.unitAmount!.required) err.push("Required field");
    if (!this.$v.unitAmount!.min) err.push("Cannot create a free product");
    return err;
  }
  get countErrors() {
    const err: string[] = [];
    if (!this.$v.employees!.$dirty) return err;
    if (!this.$v.employees!.required) err.push("Required field");
    if (!this.$v.employees!.min)
      err.push("Cannot add less than 2 employees (incl. yourself)");
    return err;
  }

  async create() {
    this.$v.$touch();
    if (this.$v.$invalid || this.loading) return;

    const metadata = { employees: this.employees };
    const data = {
      name: this.name,
      description: this.description,
      active: this.active,
      metadata: metadata,
      unitAmount: Math.round(this.unitAmount * 100),
    };
    this.createProduct(data);
  }
}
