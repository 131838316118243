
























import Vue from "vue";
import { Action } from "vuex-class";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";
import CouponItem from "../components/CouponItem.vue";
import CreateCoupon from "../components/CreateCoupon.vue";

@Component({ components: { CouponItem, CreateCoupon } })
export default class StripeCoupons extends Vue {
  @Action("displaySnackbar") displaySnackbar!: Function;

  loading = false;
  coupons: any[] = [];

  async getCoupons() {
    this.loading = true;

    try {
      const end = "/api/Coupons";
      const coupons = (await api.get(end)) as any[];
      this.coupons = coupons;
    } catch (error) {
      const fallback = "Could not retrieve coupon codes";
      const message = error.message || fallback;
      this.displaySnackbar(message);
    }

    this.loading = false;
  }
  created() {
    this.getCoupons();
  }
}
