














































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CouponItem extends Vue {
  @Prop({ default: () => {} }) item!: any;

  loading = false;
  dialog = false;

  get discount() {
    const { amount_off, percent_off, duration } = this.item;
    if (amount_off) {
      const amount = (amount_off / 100).toFixed(2);
      return `€${amount} OFF ${duration}`;
    }
    return `${percent_off}% OFF ${duration}`;
  }

  async update() {
    // TODO: Call api
  }

  created() {}
}
